import React from 'react';

export default function NoPage() {
  return (
    <div>
        <h1>Error: 404</h1>
        <p>Page not found</p>
    </div>
  )
}
